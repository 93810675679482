export const environment = {
  firebase: {
    apiKey: "AIzaSyDYtA1jMT9PCnZiNVtJRJ-tjSolQCJaapA",
    authDomain: "river-sky-410815.firebaseapp.com",
    projectId: "river-sky-410815",
    storageBucket: "river-sky-410815.appspot.com",
    messagingSenderId: "369736960402",
    appId: "1:369736960402:web:179d8e76517cc58d2f594b"
  },
  production: true,
  demo: '', // other possible options are, saas creative and modern
  GOOGLE_MAPS_API_KEY: 'AIzaSyC5pcAkhXSo6-KqV9RtZdUAhdJno7r_8b0'
};
