<footer class="bg-light py-1">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="">
                    <p class="text-dark text-center mb-0">{{today | date:'yyyy'}} © Research Questionnaire
                    </p>
                </div>
            </div>
        </div>
    </div>
</footer>