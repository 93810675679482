<!-- Footer Start -->
<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">
                © Research Questionnaire {{today| date : 'yyyy'}}
            </div>
            <div class="col-md-6">
                <div class="text-md-end footer-links d-none d-md-block">
                    <a role="button" (click)="navigateTo('about')">About</a>
                    <a role="button" (click)="navigateTo('support')">Support</a>
                    <a role="button" (click)="navigateTo('contact us')">Contact Us</a>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- end Footer -->